<template>
  <div class="mb-10">
    <UserInfo :memberInfoData="memberInfoData" :memberId="memberId" />
  </div>
</template>

<script setup lang="ts">
/**
 * import
 */
import { ref, computed, onMounted } from "vue"
import axios from "axios"
import UserInfo from "../components/UserInfo.vue"
import { MembersApi } from "../api/MembersApi"
import type { Member } from "../api/memberType"

/**
 * リアクティブ
 */
let memberInfoData = ref<Member>({
  id: 0,
  name: "",
  birthday: "",
  blood: "",
  mbti: "",
  hobby: "",
  strength: "",
  specialized: "",
  post: "",
  origin: "",
  begin: "",
  gear_brand: "",
  profile_photo: [],
  x: "",
  instagram: "",
  tiktok: "",
  youTube: "",
})
const memberId = ref(0)
/**
 * 変数
 */

/**
 * 変数(メソッド)
 */

/**
 * method
 */

async function getMemberList() {
  const membersApi = new MembersApi()
  try {
    const fetchMemberData = await membersApi.getMemberList({
      memberId: "2",
    })
    memberInfoData.value = fetchMemberData
  } catch (error: any) {
    console.log("error", error)
  }
}

/**
 * computed
 */

/**
 * ライフサイクル
 */

onMounted(() => {
  getMemberList()
  // axios
  //   .get("http://localhost:8000/api/v1/members/")
  //   .then((response) => (memberInfoData.value = response.data))
  //   .then((response) => {
  //     console.log("status:", response.status)
  //     console.log("axiosGetData:", response.data)
  //   })
  //   .catch((err) => {
  //     console.log("axiosGetErr", err)
  //   })
})
</script>
<style scoped></style>
