<template>
  <SelectProject :projectListData="projectListData" />
</template>

<script setup lang="ts">
/**
 * import
 */
import { ref, computed, onMounted } from "vue"
import SelectProject from "../components/SelectProject.vue"
import { ProjectApi } from "../api/ProjectApi"
import type { Project } from "../api/ProjectType"

/**
 * リアクティブ
 */
let projectListData = ref<Project[]>([])
/**
 * 変数
 */

/**
 * 変数(メソッド)
 */

/**
 * method
 */

async function getProjectList() {
  const projectApi = new ProjectApi()
  try {
    const fetchProjectListData = await projectApi.getProjectList()
    projectListData.value = fetchProjectListData
  } catch (error: any) {
    console.log("error", error)
  }
}

/**
 * computed
 */

/**
 * ライフサイクル
 */

onMounted(() => {
  getProjectList()
})
</script>
<style scoped></style>
