import ApiBaseForAdmin from "./ApiBaseForAdmin"
import { type GetProjectRequest, GetProjectResponse, PostProjectRequest, PostProjectResponse } from "../api/ProjectType"
import { ENDPOINTS } from "./endpoints"
export class ProjectApi extends ApiBaseForAdmin {
  async getProjectList(params?: GetProjectRequest): Promise<GetProjectResponse[]> {
    const memberId = params?.memberId || ""

    if (memberId) {
      let endpoint = ENDPOINTS.PROJECT.GET

      endpoint = endpoint.replace("{memberId}", memberId)
      return await this.get(endpoint, memberId)
    }
    const endpoint = ENDPOINTS.PROJECT.GETLIST

    return await this.get(endpoint, {})
  }
  async getProject(params?: GetProjectRequest): Promise<GetProjectResponse> {
    const memberId = params?.memberId || ""

    if (memberId) {
      let endpoint = ENDPOINTS.PROJECT.GET

      endpoint = endpoint.replace("{memberId}", memberId)
      return await this.get(endpoint, memberId)
    }
    const endpoint = ENDPOINTS.PROJECT.GETLIST

    return await this.get(endpoint, {})
  }

  async postProject(formData: FormData): Promise<PostProjectResponse> {
    return await this.post(ENDPOINTS.PROJECT.POST, formData)
  }
  async updateProject(formData: FormData): Promise<PostProjectResponse> {
    const projectId = formData.get("id")
    let endpoint = ENDPOINTS.PROJECT.PATCH
    if (typeof projectId === "string") {
      endpoint = endpoint.replace("{projectId}", projectId)
    } else {
      // idが取得できない、または文字列でない場合のエラー処理
      throw new Error("Member ID is missing or invalid in the form data.")
    }

    return await this.patch(endpoint, formData)
  }
}
