<template>
  <div class="text-h5 ml-5">プロジェクト編集</div>
  <v-sheet class="pa-7 mx-16" border rounded>
    <Form @submit="submitProject()">
      <div class="">
        <div class="my-15">
          <span class="">プロジェクト名:</span>
          <v-text-field class="" label="" v-model="project_name" hide-details="auto"></v-text-field>
          <span class="error-display">{{ projectNameError }}</span>
        </div>
        <div class="my-15">
          <span class="">開発者:</span>
          <span>{{ projectListData.developer_name }}</span>
        </div>
        <div class="my-15"><span>プロフィール画像:</span></div>
        <div class="my-15">
          <v-file-input
            label="ファイルを選ぶ"
            v-model="selectedFile"
            @change="previewImage"
            accept="image/*"
          ></v-file-input>
        </div>
        <div v-if="imagePreview" class="image-preview d-flex flex-column align-items-center">
          <span>プレビュー</span>
          <img :src="imagePreview" alt="Image Preview" />
        </div>
        <div v-else-if="projectListData?.project_image" class="image-preview d-flex flex-column align-items-center">
          <span>現在の写真</span>
          <v-img class="profile-photo" :src="'http://127.0.0.1:8000' + projectListData?.project_image"></v-img>
        </div>
      </div>
      <div class="d-flex mt-2 mr-16 justify-end">
        <v-btn class="px-10" color="blue" @click="submitProject()" :disabled="!meta.valid">保存</v-btn>
      </div>
    </Form>
  </v-sheet>
</template>

<script setup lang="ts">
/**
 * import
 */
import { ref, computed, defineProps, onMounted, watch } from "vue"
import { useMemberStore } from "@/stores/memberStore" // Pinla 状態管理
import { ProjectApi } from "../api/ProjectApi"
import type { Project } from "../api/ProjectType"
import { useForm, useField } from "vee-validate" // バリデーション
import * as yup from "yup"
import router from "@/router"
/**
 * props
 */
const props = defineProps<{
  projectListData: Project
}>()
/**
 * リアクティブ
 */

const selectedFile = ref([])
const imagePreview = ref("")
/**
 * 変数
 */
// Yupバリデーションスキーマの作成
const schema = yup.object({
  project_name: yup.string().max(50, "50文字以下にしてください").required("名前は必須です"),
})

// フォーム全体のバリデーション関連
const { values, meta } = useForm({
  validationSchema: schema,
})

// フィールドのバリデーション、値関連
const {
  value: project_name,
  errorMessage: projectNameError,
  setValue: setProjectName,
} = useField("project_name", undefined)
const {
  value: project_image,
  errorMessage: projectImageError,
  setValue: setProjectImage,
} = useField("project_image", undefined)

/**
 * 変数(メソッド)
 */
const previewImage = () => {
  // 'selectedFile' がファイルの配列を持っているか確認
  if (selectedFile.value && selectedFile.value.length > 0) {
    // 最初のファイルを取得
    const file = selectedFile.value[0]
    if (file) {
      imagePreview.value = URL.createObjectURL(file)
    }
  } else {
    // 選択されていない場合、または配列が空の場合はプレビューをクリア
    imagePreview.value = ""
  }
}
/**
 * method
 */

// メンバー情報更新
async function submitProject() {
  const formData = new FormData()
  formData.append("id", props.projectListData.id.toString())
  formData.append("project_name", values.project_name)

  if (selectedFile.value.length > 0) {
    const file = selectedFile.value[0] // 配列の最初の要素を取得
    formData.append("project_image", file) // 'file' をFormDataに追加
  }
  console.log(formData.get("project_image"))

  const projectApi = new ProjectApi()
  try {
    const response = await projectApi.updateProject(formData)
    console.log(response)
    // TOPへ
    router.push("/")
  } catch (error) {
    console.error(error)
    // エラー処理
  }
}
/**
 * watch
 */
watch(
  () => props.projectListData,
  (newValue) => {
    if (newValue) {
      setProjectName(newValue.project_name)
      setProjectImage(newValue.project_image)
    }
  },
  { immediate: true }
)
/**
 * ライフサイクル
 */
// onMounted(() => {
// })
</script>
<style scoped>
.error-display {
  color: red;
}
</style>
