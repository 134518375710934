<template>
  <EditProject :projectListData="projectListData" />
</template>

<script setup lang="ts">
/**
 * import
 */
import { ref, computed, onMounted } from "vue"
import { useRoute } from "vue-router"
import EditProject from "../components/EditProject.vue"
import { ProjectApi } from "../api/ProjectApi"
import type { Project } from "../api/ProjectType"
import { type GetProjectRequest } from "../api/ProjectType"

/**
 * リアクティブ
 */

let projectListData = ref<Project>({
  id: 0,
  project_name: "",
  developer: 0,
  developer_name: "",
  tube_labels: [],
  update_time: "",
  project_image: null,
})

/**
 * 変数
 */

const route = useRoute()
/**
 * 変数(メソッド)
 */

/**
 * method
 */
async function getProject() {
  const projectApi = new ProjectApi()
  try {
    const id = route.params.id
    let request: GetProjectRequest | undefined
    if (typeof id === "string") {
      request = { memberId: id }
    }
    const fetchProjectListData = await projectApi.getProject(request)
    projectListData.value = fetchProjectListData
  } catch (error: any) {
    console.log("error", error)
  }
}
/**
 * computed
 */

/**
 * ライフサイクル
 */

onMounted(() => {
  getProject()
})
</script>
<style scoped></style>
