import axios, { type AxiosInstance, type AxiosResponse } from "axios"
import type { ResponceError } from "../api/ErrorType"
export default class ApiBase {
  protected client: AxiosInstance

  constructor(baseURL: string, headers?: any) {
    this.client = axios.create({
      baseURL,
      timeout: 10000,
      headers: headers || {
        "Access-Control-Allow-Origin": "https://admin.laborat.jp/",
      },
    })
  }
  protected async get<T>(url: string, params?: any): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.client.get<T>(url, params)

      return response.data
    } catch (error: any) {
      const keys = Object.keys(error.response.data)
      let firstKey = ""
      if (keys.length > 0) {
        firstKey = keys[0]
      }
      const axiosError: ResponceError = {
        message: error.response.data[firstKey],
      }
      throw axiosError
    }
  }
  protected async put<T>(url: string, params?: any): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.client.put<T>(url, params)

      return response.data
    } catch (error: any) {
      const keys = Object.keys(error.response.data)
      let firstKey = ""
      if (keys.length > 0) {
        firstKey = keys[0]
      }
      const axiosError: ResponceError = {
        message: error.response.data[firstKey],
      }
      throw axiosError
    }
  }
  protected async post<T>(url: string, params?: any): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.client.post<T>(url, params)

      return response.data
    } catch (error: any) {
      const keys = Object.keys(error.response.data)
      let firstKey = ""
      if (keys.length > 0) {
        firstKey = keys[0]
      }
      const axiosError: ResponceError = {
        message: error.response.data[firstKey],
      }
      throw axiosError
    }
  }
  protected async patch<T>(url: string, params?: any): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.client.patch<T>(url, params)

      return response.data
    } catch (error: any) {
      const keys = Object.keys(error.response.data)
      let firstKey = ""
      if (keys.length > 0) {
        firstKey = keys[0]
      }
      const axiosError: ResponceError = {
        message: error.response.data[firstKey],
      }
      throw axiosError
    }
  }
}
