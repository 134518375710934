import { defineStore } from "pinia"
import type { Member } from "../api/memberType"

export const useMemberStore = defineStore("memberStore", {
  state: () => ({
    memberInfoData: {} as Member,
  }),
  actions: {
    setMemberInfoData(data: Member) {
      this.memberInfoData = data
    },
  },
})
