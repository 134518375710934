<template>
  <div class="text-h5 ml-5">ユーザー情報編集</div>
  <v-sheet class="pa-7 mx-16" border rounded>
    <Form @submit="submitMember()">
      <div class="">
        <div class="my-15">
          <span>Name:</span>
          <v-text-field class="" label="" v-model="name" hide-details="auto"></v-text-field>
          <span class="error-display">{{ nameError }}</span>
          <ErrorMessage name="birthday" />
        </div>
        <div class="my-15">
          <span>Birth Day:</span>
          <v-text-field label="" v-model="birthday" hide-details="auto"></v-text-field>
          <span class="error-display">{{ birthdayError }}</span>
        </div>
        <div class="my-15">
          <span>Blood:</span>
          <v-text-field label="" v-model="blood" hide-details="auto"></v-text-field>
          <span class="error-display">{{ bloodError }}</span>
        </div>
        <div class="my-15">
          <span>MBTI:</span>
          <v-text-field label="" v-model="mbti" hide-details="auto"></v-text-field>
          <span class="error-display">{{ mbtiError }}</span>
        </div>
        <div class="my-15">
          <span>Hobby:</span>
          <v-text-field label="" v-model="hobby" hide-details="auto"></v-text-field>
          <span class="error-display">{{ hobbyError }}</span>
        </div>
        <p>Property</p>
        <div class="my-15">
          <span>Strength:</span>
          <v-text-field label="" v-model="strength" hide-details="auto"></v-text-field>
          <span class="error-display">{{ strengthError }}</span>
        </div>
        <div class="my-15">
          <span>Specialized:</span>
          <v-text-field label="" v-model="specialized" hide-details="auto"></v-text-field>
          <span class="error-display">{{ specializedError }}</span>
        </div>
        <div class="my-15">
          <span>Post:</span>
          <v-text-field label="" v-model="post" hide-details="auto"></v-text-field>
          <span class="error-display">{{ postError }}</span>
        </div>
        <p>Source</p>
        <div class="my-15">
          <span>Origin:</span>
          <v-text-field label="" v-model="origin" hide-details="auto"></v-text-field>
          <span class="error-display">{{ originError }}</span>
        </div>
        <div class="my-15">
          <span>Begin:</span>
          <v-text-field label="" v-model="begin" hide-details="auto"></v-text-field>
          <span class="error-display">{{ beginError }}</span>
        </div>
        <div class="my-15">
          <span>Gear Brand:</span>
          <v-text-field label="" v-model="gear_brand" hide-details="auto"></v-text-field>
          <span class="error-display">{{ gearBrandError }}</span>
        </div>
        <p>SNS</p>
        <div class="my-15">
          <span>X:</span>
          <v-text-field label="" v-model="x" hide-details="auto"></v-text-field>
          <span class="error-display">{{ xError }}</span>
        </div>
        <div class="my-15">
          <span>Instagram:</span>
          <v-text-field label="" v-model="instagram" hide-details="auto"></v-text-field>
          <span class="error-display">{{ instagramError }}</span>
        </div>
        <div class="my-15">
          <span>Tiktok:</span>
          <v-text-field label="" v-model="tiktok" hide-details="auto"></v-text-field>
          <span class="error-display">{{ tiktokError }}</span>
        </div>
        <div class="my-15">
          <span>YouTube:</span>
          <v-text-field label="" v-model="youTube" hide-details="auto"></v-text-field>
          <span class="error-display">{{ youTubeError }}</span>
        </div>
        <div class="my-15"><span>プロフィール画像:</span>{{ profile_photo }}</div>
        <div class="my-15">
          <v-file-input
            label="ファイルを選ぶ"
            v-model="selectedFile"
            @change="previewImage"
            accept="image/*"
          ></v-file-input>
        </div>
        <div v-if="imagePreview" class="image-preview d-flex flex-column align-items-center">
          <span>プレビュー</span>
          <img :src="imagePreview" alt="Image Preview" />
        </div>
        <div v-else-if="memberInfoData?.profile_photo" class="image-preview d-flex flex-column align-items-center">
          <span>現在の写真</span>
          <v-img class="profile-photo" :src="'http://127.0.0.1:8000' + memberInfoData?.profile_photo"></v-img>
        </div>
      </div>
      <div class="d-flex mt-2 mr-16 justify-end">
        {{ meta }}{{ values }}
        <v-btn class="px-10" color="blue" @click="submitMember()" :disabled="!meta.valid">保存</v-btn>
      </div>
    </Form>
  </v-sheet>
</template>

<script setup lang="ts">
/**
 * import
 */
import { ref, computed, defineProps, onMounted } from "vue"
import type { Member } from "../api/memberType" // メンバー型
import { useMemberStore } from "@/stores/memberStore" // Pinla 状態管理
import { MembersApi } from "../api/MembersApi" // メンバーAPI
import { useForm, useField } from "vee-validate" // バリデーション
import * as yup from "yup"
import router from "@/router"
/**
 * props
 */
// const props = defineProps<{
//   memberInfoData: Member
// }>()
/**
 * リアクティブ
 */
const store = useMemberStore()
let memberInfoData = ref<Member>({
  id: 0,
  name: "",
  birthday: "",
  blood: "",
  mbti: "",
  hobby: "",
  strength: "",
  specialized: "",
  post: "",
  origin: "",
  begin: "",
  gear_brand: "",
  profile_photo: [],
  x: "",
  instagram: "",
  tiktok: "",
  youTube: "",
})
const selectedFile = ref([])
const imagePreview = ref("")
/**
 * 変数
 */
// Yupバリデーションスキーマの作成
const schema = yup.object({
  name: yup.string().max(50, "50文字以下にしてください").required("名前は必須です"),
  birthday: yup
    .string()
    .matches(/^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/, "誕生日はmm/dd形式で入力してください（例：12/31）")
    .max(5, "5文字以下にしてください")
    .required("誕生日は必須です"),
  blood: yup.string().max(100, "100文字以下にしてください").required("bloodは必須です"),
  mbti: yup.string().max(100, "100文字以下にしてください").required("mbtiは必須です"),
  hobby: yup.string().max(100, "100文字以下にしてください").required("hobbyは必須です"),
  strength: yup.string().max(100, "100文字以下にしてください").required("strengthは必須です"),
  specialized: yup.string().max(100, "100文字以下にしてください").required("specializedは必須です"),
  post: yup.string().max(100, "100文字以下にしてください").required("postは必須です"),
  origin: yup.string().max(100, "100文字以下にしてください").required("originは必須です"),
  begin: yup.string().max(100, "100文字以下にしてください").required("beginは必須です"),
  gear_brand: yup.string().max(100, "100文字以下にしてください").required("gear_brandは必須です"),
  // profile_photo: yup.string().required("profile_photoは必須です"),
  x: yup.string().max(250, "250文字以下にしてください").url("有効なURLを入力してください"),
  instagram: yup.string().max(250, "250文字以下にしてください").url("有効なURLを入力してください"),
  tiktok: yup.string().max(250, "250文字以下にしてください").url("有効なURLを入力してください"),
  youTube: yup.string().max(250, "250文字以下にしてください").url("有効なURLを入力してください"),
})

// フォーム全体のバリデーション関連
const { values, meta } = useForm({
  validationSchema: schema,
})

// フィールドのバリデーション、値関連
const { value: name, errorMessage: nameError, setValue: setName } = useField("name", undefined)
const { value: birthday, errorMessage: birthdayError, setValue: setBirthday } = useField("birthday", undefined)
const { value: blood, errorMessage: bloodError, setValue: setBlood } = useField("blood", undefined)
const { value: mbti, errorMessage: mbtiError, setValue: setMbti } = useField("mbti", undefined)
const { value: hobby, errorMessage: hobbyError, setValue: setHobby } = useField("hobby", undefined)
const { value: strength, errorMessage: strengthError, setValue: setStrength } = useField("strength", undefined)
const {
  value: specialized,
  errorMessage: specializedError,
  setValue: setSpecialized,
} = useField("specialized", undefined)
const { value: post, errorMessage: postError, setValue: setPost } = useField("post", undefined)
const { value: origin, errorMessage: originError, setValue: setOrigin } = useField("origin", undefined)
const { value: begin, errorMessage: beginError, setValue: setBegin } = useField("begin", undefined)
const { value: gear_brand, errorMessage: gearBrandError, setValue: setGearBrand } = useField("gear_brand", undefined)
const { value: x, errorMessage: xError, setValue: setX } = useField("x", undefined)
const { value: instagram, errorMessage: instagramError, setValue: setInstagram } = useField("instagram", undefined)
const { value: tiktok, errorMessage: tiktokError, setValue: setTiktok } = useField("tiktok", undefined)
const { value: youTube, errorMessage: youTubeError, setValue: setYouTube } = useField("youTube", undefined)
const {
  value: profile_photo,
  errorMessage: profilePhotoError,
  setValue: setProfilePhoto,
} = useField("profile_photo", undefined)

/**
 * 変数(メソッド)
 */
const previewImage = () => {
  // 'selectedFile' がファイルの配列を持っているか確認
  if (selectedFile.value && selectedFile.value.length > 0) {
    // 最初のファイルを取得
    const file = selectedFile.value[0]
    if (file) {
      imagePreview.value = URL.createObjectURL(file)
    }
  } else {
    // 選択されていない場合、または配列が空の場合はプレビューをクリア
    imagePreview.value = ""
  }
}
/**
 * method
 */

// メンバー情報更新
async function submitMember() {
  const formData = new FormData()
  formData.append("id", memberInfoData.value.id.toString())
  formData.append("name", values.name)
  formData.append("birthday", values.birthday)
  formData.append("blood", values.blood)
  formData.append("mbti", values.mbti)
  formData.append("hobby", values.hobby)
  formData.append("strength", values.strength)
  formData.append("specialized", values.specialized)
  formData.append("post", values.post)
  formData.append("origin", values.origin)
  formData.append("begin", values.begin)
  formData.append("gear_brand", values.gear_brand)
  formData.append("x", values.x)
  formData.append("instagram", values.instagram)
  formData.append("tiktok", values.tiktok)
  formData.append("youTube", values.youTube)

  if (selectedFile.value.length > 0) {
    const file = selectedFile.value[0] // 配列の最初の要素を取得
    formData.append("profile_photo", file) // 'file' をFormDataに追加
  }
  console.log(formData.get("profile_photo"))

  const membersApi = new MembersApi()
  try {
    const response = await membersApi.updateMember(formData)
    console.log(response)
    // TOPへ
    router.push("/")
  } catch (error) {
    console.error(error)
    // エラー処理
  }
}
function setValue() {
  setName(memberInfoData.value.name)
  setBirthday(memberInfoData.value.birthday)
  setBlood(memberInfoData.value.blood)
  setMbti(memberInfoData.value.mbti)
  setHobby(memberInfoData.value.hobby)
  setStrength(memberInfoData.value.strength)
  setSpecialized(memberInfoData.value.strength)
  setPost(memberInfoData.value.post)
  setOrigin(memberInfoData.value.origin)
  setBegin(memberInfoData.value.begin)
  setGearBrand(memberInfoData.value.gear_brand)
  setX(memberInfoData.value.x)
  setInstagram(memberInfoData.value.instagram)
  setTiktok(memberInfoData.value.tiktok)
  setYouTube(memberInfoData.value.youTube)
}

/**
 * ライフサイクル
 */
onMounted(() => {
  console.log("onMounted", store.memberInfoData)
  memberInfoData.value = store.memberInfoData
  console.log("onMounted", memberInfoData.value)
  setValue()
})
</script>
<style scoped>
.error-display {
  color: red;
}
</style>
