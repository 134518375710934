<template>
  <div class="text-h5 ml-5">プロジェクト作成</div>
  <v-sheet class="pa-7 mx-16" border rounded>
    <Form @submit="submitProject()">
      <div class="">
        <div class="my-15">
          <span class="">プロジェクト名:</span>
          <v-text-field class="" label="" v-model="project_name" hide-details="auto"></v-text-field>
          <span class="error-display">{{ projectNameError }}</span>
        </div>
        <div class="my-15">
          <span>TUBE LABEL:</span>
          <v-text-field label="" v-model="tube_label" hide-details="auto"></v-text-field>
          <span class="error-display">{{ tubeLabelError }}</span>
        </div>
        <div class="my-15">
          <span>音源:</span>
          <v-file-input label="ファイルを選ぶ" v-model="sound_data" show-size counter accept="audio/*"></v-file-input>
          <span class="error-display">{{ soundDataError }}</span>
        </div>
        <div class="my-15">
          <span>プロジェクト画像:</span>
          <v-file-input
            label="ファイルを選ぶ"
            v-model="project_image"
            show-size
            counter
            accept="image/*"
          ></v-file-input>
        </div>
      </div>
      <div class="d-flex mt-2 mr-16 justify-end">
        <v-btn class="px-10" color="blue" @click="submitProject()" :disabled="!meta.valid">保存</v-btn>
      </div>
    </Form>
  </v-sheet>
</template>

<script setup lang="ts">
/**
 * import
 */
import { ref, computed } from "vue"
import type { AddProject } from "../api/ProjectType" // メンバー型
import { useMemberStore } from "@/stores/memberStore" // Pinla 状態管理
import { ProjectApi } from "../api/ProjectApi" // メンバーAPI
import { useForm, useField } from "vee-validate" // バリデーション
import * as yup from "yup"
import router from "@/router"
/**
 * props
 */
/**
 * computed
 */
/**
 * リアクティブ
 */
/**
 * 変数
 */
// ファイルが存在するかどうかを確認するカスタム検証関数
const fileSchema = yup.mixed().test("fileExists", "ファイルを選択してください", (file) => {
  // `File`または`File[]`型として扱う
  if (file instanceof File) {
    return true // 単一のファイルが選択されている場合
  } else if (Array.isArray(file)) {
    return file.length > 0 // 複数のファイルが選択されている場合
  }
  return false // ファイルが選択されていない場合
})
const schema = yup.object({
  project_name: yup.string().max(50, "50文字以下にしてください").required("名前は必須です"),
  tube_label: yup.string().max(50, "50文字以下にしてください").required("TUBE LABELは必須です"),
  sound_data: fileSchema,
})

// フォーム全体のバリデーション関連
const { values, meta } = useForm({
  validationSchema: schema,
})

// フィールドのバリデーション、値関連
const { value: project_name, errorMessage: projectNameError } = useField("project_name", undefined, {
  initialValue: "",
})
const { value: tube_label, errorMessage: tubeLabelError } = useField("tube_label", undefined, { initialValue: "" })
const { value: sound_data, errorMessage: soundDataError } = useField("sound_data", undefined, { initialValue: [] })
const { value: project_image, errorMessage: projectImageError } = useField("project_image", undefined, {
  initialValue: [],
})

/**
 * 変数(メソッド)
 */

/**
 * method
 */

// メンバー情報更新
async function submitProject() {
  const formData = new FormData()
  formData.append("project_name", values.project_name)
  formData.append("developer", "1")
  if (values.project_image[0]) {
    formData.append("project_image", values.project_image[0])
  }
  formData.append("file", values.sound_data[0])
  formData.append("label", values.tube_label)

  const projectApi = new ProjectApi()
  try {
    const response = await projectApi.postProject(formData)
    console.log(response)
    // TOPへ
    router.push("/")
  } catch (error) {
    console.error(error)
    // エラー処理
  }
}

/**
 * ライフサイクル
 */
</script>
<style scoped>
.error-display {
  color: red;
}
</style>
