export const ENDPOINTS = {
  MEMBER: {
    GETLIST: "/members/{memberId}/",
    PUT: "/members/{memberId}/",
  },
  PROJECT: {
    GETLIST: "/projects/",
    GET: "/projects/{memberId}/",
    POST: "/projects/",
    PATCH: "/projects/{projectId}/",
  },
}
