import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import "vuetify/styles" // Vuetify
import { createVuetify } from "vuetify"
import * as components from "vuetify/components"
import * as directives from "vuetify/directives"
import "../src/assets/css/global.css"
import "@mdi/font/css/materialdesignicons.min.css"
import { createPinia } from "pinia"
const vuetify = createVuetify({
  components,
  directives,
})

const app = createApp(App)

// グローバルプロパティ
app.config.globalProperties.$members = [
  {
    id: 0,
    name: "Kento",
    birth_day: "06-10",
    x_url: "https://twitter.com/Kento_LABORAT",
  },
  {
    id: 1,
    name: "Takumi",
    birth_day: "02-03",
    x_url: "https://twitter.com/Takumi_LABORAT",
  },
  {
    id: 2,
    name: "Kyohei Shiba",
    birth_day: "06-01",
    x_url: "https://twitter.com/Kyohei_Shiba",
  },
  {
    id: 3,
    name: "Yusuke",
    birth_day: "01-22",
    x_url: "https://twitter.com/bass_Ysk",
  },
]

app.use(vuetify).use(createPinia()).use(router).mount("#app")
