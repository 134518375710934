<template>
  <div class="text-h5 ml-5">ユーザー情報</div>
  <v-sheet class="pa-7 mx-16" border rounded>
    <div class="d-flex justify-center">
      <v-img
        v-if="memberInfoData?.profile_photo"
        class="profile-photo"
        :src="'http://127.0.0.1:8000' + memberInfoData?.profile_photo"
      ></v-img>
      <span v-else class="profile-photo">No photo</span>
    </div>
    <div>
      <div class="ga-4 d-flex">
        <span>Name:</span>
        <div>{{ memberInfoData?.name }}</div>
      </div>
      <div class="ga-4 d-flex">
        <span>Birth Day:</span>
        <div>{{ memberInfoData?.birthday }}</div>
      </div>
      <div class="ga-4 d-flex">
        <span>Blood:</span>
        <div>{{ memberInfoData?.blood }}</div>
      </div>
      <div class="ga-4 d-flex">
        <span>MBTI:</span>
        <div>{{ memberInfoData?.mbti }}</div>
      </div>
      <div class="ga-4 d-flex">
        <span>Hobby:</span>
        <div>{{ memberInfoData?.hobby }}</div>
      </div>
      <p>Property</p>
      <div class="ga-4 d-flex">
        <span>Strength:</span>
        <div>{{ memberInfoData?.strength }}</div>
      </div>
      <div class="ga-4 d-flex">
        <span>Specialized:</span>
        <div>{{ memberInfoData?.specialized }}</div>
      </div>
      <div class="ga-4 d-flex">
        <span>Post:</span>
        <div>{{ memberInfoData?.post }}</div>
      </div>
      <p>Source</p>
      <div class="ga-4 d-flex">
        <span>Origin:</span>
        <div>{{ memberInfoData?.origin }}</div>
      </div>
      <div class="ga-4 d-flex">
        <span>Begin:</span>
        <div>{{ memberInfoData?.begin }}</div>
      </div>
      <div class="ga-4 d-flex">
        <span>Gear Brand:</span>
        <div>{{ memberInfoData?.gear_brand }}</div>
      </div>
      <p>SNS</p>
      <div class="ga-4 d-flex">
        <span>X:</span>
        <div>
          <a :href="memberInfoData?.x">{{ memberInfoData?.x }}</a>
        </div>
      </div>
      <div class="ga-4 d-flex">
        <span>Instagram:</span>
        <div>
          <a :href="memberInfoData?.instagram">{{ memberInfoData?.instagram }}</a>
        </div>
      </div>
      <div class="ga-4 d-flex">
        <span>Tiktok:</span>
        <div>
          <a :href="memberInfoData?.tiktok">{{ memberInfoData?.tiktok }}</a>
        </div>
      </div>
      <div class="ga-4 d-flex">
        <span>YouTube:</span>
        <div>
          <a :href="memberInfoData?.youTube">{{ memberInfoData?.youTube }}</a>
        </div>
      </div>
    </div>
  </v-sheet>
  <div class="d-flex mt-2 mr-16 justify-end">
    <v-btn class="px-10" color="blue" @click="navigateToMemberEdit()">編集</v-btn>
  </div>
</template>

<script setup lang="ts">
/**
 * import
 */
import { ref, computed, onMounted, defineProps } from "vue"
import type { Member } from "../api/memberType"
import { useMemberStore } from "@/stores/memberStore"
import { useRouter } from "vue-router"

/**
 * props
 */
const props = defineProps<{
  memberInfoData: Member
  memberId: number
}>()
/**
 * リアクティブ
 */

/**
 * 変数
 */
const router = useRouter()
/**
 * 変数(メソッド)
 */

/**
 * method
 */
function navigateToMemberEdit() {
  const store = useMemberStore()
  store.setMemberInfoData(props.memberInfoData)
  router.push("user-edit")
}
/**
 * computed
 */

/**
 * ライフサイクル
 */
</script>
<style scoped>
.profile-photo {
  min-width: 100px;
  max-width: 400px;
}
</style>
