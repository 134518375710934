import ApiBaseForAdmin from "./ApiBaseForAdmin"
import { type GetMemberRequest, GetMemberResponse, UpdateMemberRequest, UpdateMemberResponse } from "../api/memberType"
import { ENDPOINTS } from "./endpoints"
export class MembersApi extends ApiBaseForAdmin {
  async getMemberList(params: GetMemberRequest): Promise<GetMemberResponse> {
    let endpoint = ENDPOINTS.MEMBER.GETLIST
    endpoint = endpoint.replace("{memberId}", params.memberId)
    return await this.get(endpoint, params)
  }
  async updateMember(formData: FormData): Promise<UpdateMemberResponse> {
    // FormDataからidを取得して文字列に変換
    const memberId = formData.get("id")
    console.log("updateMember2", memberId)
    let endpoint = ENDPOINTS.MEMBER.PUT
    if (typeof memberId === "string") {
      endpoint = endpoint.replace("{memberId}", memberId)
    } else {
      // idが取得できない、または文字列でない場合のエラー処理
      throw new Error("Member ID is missing or invalid in the form data.")
    }
    return await this.put(endpoint, formData)
  }
}
