<template>
  <div class="text-h5 ml-5">プロジェクト一覧</div>
  <v-sheet class="pa-7 mx-16" border rounded>
    <v-data-table
      :headers="projectHeaders"
      :items="projectListData"
      item-value="id"
      show-expand
      @click:row="onProjectRowClick"
    >
      <template v-slot:expanded-row="{ columns, item }">
        <td></td>
        <td :colspan="columns.length">
          <v-data-table
            :headers="tubeLabelHeaders"
            :items="item.tube_labels"
            item-value="id"
            :hide-default-footer="true"
            @click:row="onTubeLabelRowClick"
          ></v-data-table>
        </td>
      </template>
    </v-data-table>
  </v-sheet>
</template>
<script setup lang="ts">
/**
 * import
 */
import { ref, computed, onMounted, defineProps } from "vue"
import type { Project } from "../api/ProjectType"
import { useMemberStore } from "@/stores/memberStore"
import { useRouter } from "vue-router"
const expanded = ref([])
/**
 * props
 */
const props = defineProps<{
  projectListData: Project[]
}>()
/**
 * リアクティブ
 */

/**
 * 変数
 */
const router = useRouter()

const projectHeaders = [
  { title: "", key: "data-table-expand" },
  { title: "プロジェクト名", key: "project_name" },
  { title: "開発者", key: "developer_name" },
  { title: "更新時間", key: "update_time" },
  // 他の列も必要に応じて追加
]

const tubeLabelHeaders = [
  { title: "id", value: "id" },
  { title: "Tube Label", key: "label" },
  { title: "tube 開発者", value: "tube_developer_name" },
  // { title: "更新時間", value: "update_time" },
  // 他の列も必要に応じて追加
]
/**
 * 変数(メソッド)
 */

/**
 * method
 */

function onProjectRowClick(event: any, item: any) {
  console.log("test", item)
  router.push({ name: "editProject", params: { id: item.item.id.toString() } })
}
function onTubeLabelRowClick(event: any, item: any) {
  router.push({ name: "editProject", params: { id: item.item.id.toString() } })
}
/**
 * computed
 */

/**
 * ライフサイクル
 */
</script>
<style scoped>
.profile-photo {
  min-width: 100px;
  max-width: 400px;
}
.v-data-table :deep(.v-data-table__th) {
  background-color: rgb(234, 234, 234);
}
</style>
