import axios, { type AxiosInstance, type AxiosResponse } from "axios"
import { API_BASE_URL_ADMIN } from "../constants"
import ApiBase from "./ApiBase"

export default class ApiBaseForAdmin extends ApiBase {
  constructor() {
    const baseURL = API_BASE_URL_ADMIN
    // const headers = {

    // }
    super(baseURL)
  }
}
