<template>
  <p>test</p>
</template>

<script setup lang="ts">
/**
 * import
 */
import { ref } from "vue"
import { useRouter } from "vue-router"
/**
 * 変数
 */

const router = useRouter()
/**
 * リアクティブ
 */

/**
 * method
 */
</script>
