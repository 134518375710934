import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import TopView from "../views/TopPage.vue"
import UserInfo from "../views/UserInfo.vue"
import UserEdit from "../views/UserEdit.vue"
import CreateProject from "../views/CreateProject.vue"
import SelectProject from "../views/SelectProject.vue"
import EditProject from "../views/EditProject.vue"
import AddTubelabel from "../views/AddTubelabel.vue"
import EditTubelabel from "../views/AddTubelabel.vue"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "topPage",
    component: TopView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/user-info",
    name: "userInfo",
    component: UserInfo,
  },
  {
    path: "/user-edit",
    name: "userEdit",
    component: UserEdit,
  },
  {
    path: "/create-project",
    name: "createProject",
    component: CreateProject,
  },
  {
    path: "/select-project",
    name: "selectProject",
    component: SelectProject,
  },
  {
    path: "/select-project/edit-project/:id",
    name: "editProject",
    component: EditProject,
  },
  {
    path: "/select-project/add-tubelabel",
    name: "addTubelabel",
    component: AddTubelabel,
  },
  {
    path: "/select-project/edit-tubelabel",
    name: "editTubelabel",
    component: EditTubelabel,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
