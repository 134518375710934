<template>
  <v-toolbar class="px-4">
    <v-avatar class="me-4" color="grey-darken-1" size="32">{{ users.name }}</v-avatar>
    <v-btn v-for="link in links" :key="link.text" :text="link.text" @click="navigate(link.path)">
      {{ link.text }}
    </v-btn>
    <v-spacer></v-spacer>
  </v-toolbar>
</template>

<script setup lang="ts">
/**
 * import
 */
import { ref } from "vue"
import { useRouter } from "vue-router"
/**
 * 変数
 */

const links = [
  { text: "ユーザー", path: "/user-info" },
  { text: "プロジェクト作成", path: "/create-project" },
  { text: "プロジェクト編集", path: "/select-project" },
]
const router = useRouter()
/**
 * リアクティブ
 */

const users = ref({
  name: "test",
})
/**
 * method
 */

function navigate(path) {
  router.push(path)
}
</script>
<style scoped>
.wrap {
  background: linear-gradient(75deg, #aeaeae, #79808e);
}
</style>
